import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c2b5a26a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-100" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = {
  class: "preview-control",
  ref: "doc_select_ref"
}
const _hoisted_5 = { class: "col" }
const _hoisted_6 = {
  class: "preview-control",
  ref: "preview_type_ref"
}
const _hoisted_7 = { class: "col d-flex" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, null, 512 /* NEED_PATCH */)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, null, 512 /* NEED_PATCH */)
      ]),
      _createElementVNode("div", _hoisted_7, [
        ($setup.url)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "btn btn-default btn-sm btn-new-tab",
              target: "_blank",
              href: $setup.url
            }, _toDisplayString(_ctx.__("Open in a new tab")), 9 /* TEXT, PROPS */, _hoisted_8))
          : _createCommentVNode("v-if", true),
        ($setup.url)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "ml-3 btn btn-default btn-sm btn-new-tab",
              onClick: $setup.refresh
            }, _toDisplayString(_ctx.__("Refresh")), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    ($setup.url && !$setup.preview_loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.__("Generating preview...")), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.url)
      ? _withDirectives((_openBlock(), _createElementBlock("iframe", {
          key: 1,
          ref: "iframe",
          src: $setup.url,
          class: "preview-iframe",
          onLoad: _cache[0] || (_cache[0] = $event => ($setup.preview_loaded = true))
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_10)), [
          [_vShow, $setup.preview_loaded]
        ])
      : _createCommentVNode("v-if", true)
  ]))
}